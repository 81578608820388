import React from "react";
import { Navigate } from "react-router-dom";

interface ProtectedRouteProps {
  element: React.ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const hasToken = sessionStorage.getItem("KSYomxUeTK");
  return hasToken ? element : <Navigate to="/" replace />;
};

export default ProtectedRoute;
