import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const submitAnswer = async (
  questionId: string,
  optionValue: string,
  timeLapsed: number,
) => {
  try {
    const token = sessionStorage.getItem("KSYomxUeTK");
    const response = await axios.post(
      `${API_URL}/quiz-trivia/submit-answer/${questionId}`,
      {
        optionValue,
        timeLapsed,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error submitting answer:", error);
    throw error;
  }
};
