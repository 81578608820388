import React, { useState, useEffect } from "react";
import Logos from "../components/Logos";
import { fetchLeaderboard } from "../api/leaderboardAPI";
import { formatDate } from "../utils/dateUtils";
import { useNavigate } from "react-router-dom";
import { useResourceContext } from "../components/ResourceContext";
import "../styles/FadeTransition.css";
import { CSSTransition } from "react-transition-group";

const LeaderboardPage: React.FC = () => {
  const { resourcesLoaded, setResourcesLoaded } = useResourceContext();
  const navigate = useNavigate();
  const [leaderboardData, setLeaderboardData] = useState<
    { _id: string; name: string; score: number; totalTimeTaken: number }[]
  >([]);
  const [loaded, setLoaded] = useState<boolean>(false);

  const fontsLoaded = () =>
    document.fonts.ready.then(() => {
      return true;
    });

  useEffect(() => {
    const loadResources = async () => {
      await fontsLoaded();
      setResourcesLoaded(true);
      setLoaded(true);
    };

    if (!resourcesLoaded) {
      loadResources();
    } else {
      setLoaded(true);
    }
  }, [resourcesLoaded, setResourcesLoaded]);

  useEffect(() => {
    const loadLeaderboard = async () => {
      try {
        const date = formatDate(new Date());
        const response = await fetchLeaderboard(date);
        const filteredData = response.data.filter(
          (entry: any) =>
            entry.answeredQuestions && entry.answeredQuestions.length > 0,
        );
        setLeaderboardData(filteredData);
        setLoaded(true);
      } catch (error) {
        console.error("Failed to fetch leaderboard data:", error);
        setLoaded(true);
      }
    };
    loadLeaderboard();
  }, []);

  const handleExit = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/", { replace: true });
  };

  return (
    <CSSTransition in={loaded} timeout={600} classNames="fade" unmountOnExit>
      <div className="flex min-h-screen flex-col items-center px-4 py-8 font-mika">
        <Logos />
        <h1 className="font-nothing text-2xl font-medium uppercase tracking-wider text-white">
          Leaderboard
        </h1>
        <div className="w-full overflow-x-auto rounded-xl">
          <table className="mx-auto mb-8 mt-6 w-[95%] rounded-xl bg-white text-venetian-red shadow-inner-lg">
            <thead>
              <tr className="rounded-xl bg-venetian-red text-[15px] leading-normal tracking-wider text-white">
                <th className="rounded-tl-xl px-3 py-3 text-center">Rank</th>
                <th className="px-3 py-3 text-center">Name</th>
                <th className="px-3 py-3 text-center">Score</th>
                <th className="rounded-tr-xl px-3 py-3 text-center">
                  Time (in seconds)
                </th>
              </tr>
            </thead>
            <tbody className="text-[15px] font-light text-black">
              {leaderboardData.map((entry, index) => (
                <tr
                  key={entry._id}
                  className={`${
                    index === leaderboardData.length - 1 ? "" : "border-b"
                  } border-gray-200 hover:bg-opacity-50`}
                >
                  <td className="whitespace-nowrap px-3 py-3 text-center">
                    {index + 1}
                  </td>
                  <td className="px-3 py-3 text-center">{entry.name}</td>
                  <td className="px-3 py-3 text-center">{entry.score}</td>
                  <td className="px-3 py-3 text-center">
                    {entry.totalTimeTaken}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mx-auto w-[40%]">
            <button
              className="w-full rounded-xl bg-venetian-red py-3 uppercase tracking-wider text-white shadow-inner-lg"
              onClick={handleExit}
            >
              exit
            </button>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default LeaderboardPage;
