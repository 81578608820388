import React from "react";
import flipkartLogo from "../assets/images/flipkart-compressed.png";
import cmfNothing from "../assets/images/cmf-nothing-compressed.png";
import useImagePreloader from "../hooks/useImagePreloader";

const imageUrls = [flipkartLogo, cmfNothing];

const Logos: React.FC = () => {
  useImagePreloader(imageUrls);

  return (
    <div className="flex flex-col items-center">
      <a
        href="https://www.flipkart.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={flipkartLogo}
          className="mb-2 h-12 w-auto"
          alt="Flipkart-logo"
        />
      </a>
      <a href="https://in.cmf.tech/" target="_blank" rel="noopener noreferrer">
        <img src={cmfNothing} className="mb-10 h-24 w-auto" alt="CMF-NOTHING" />
      </a>
    </div>
  );
};

export default Logos;
