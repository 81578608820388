import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Logos from "../components/Logos";
import { fetchQuestions } from "../api/getQuestionsAPI";
import { submitAnswer } from "../api/submitAnswerAPI";
import Question from "../components/Question";
import "../styles/FadeTransition.css";
import { CSSTransition } from "react-transition-group";

const fetchQuestionsFromAPI = async (
  setQuestions: Function,
  setProgress: Function,
  setLoaded: Function,
) => {
  try {
    const response = await fetchQuestions();
    setQuestions(response.data);
    setProgress(0);
    setLoaded(true);
  } catch (error) {
    console.error("Failed to fetch questions:", error);
  }
};

const GamePage: React.FC = () => {
  const [questions, setQuestions] = useState<any[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [timeLeft, setTimeLeft] = useState(15);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [correctOption, setCorrectOption] = useState<string | null>(null);
  const [showAnswer, setShowAnswer] = useState(false);
  const [progress, setProgress] = useState(0);
  const [loaded, setLoaded] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const savedQuestions = sessionStorage.getItem("questions");
    const savedQuestionIndex = sessionStorage.getItem("currentQuestionIndex");
    const savedScore = sessionStorage.getItem("score");
    const savedTimeLeft = sessionStorage.getItem("timeLeft");

    if (
      savedQuestions &&
      savedQuestionIndex !== null &&
      savedScore !== null &&
      savedTimeLeft !== null
    ) {
      setQuestions(JSON.parse(savedQuestions));
      setCurrentQuestionIndex(JSON.parse(savedQuestionIndex));
      setScore(JSON.parse(savedScore));
      setTimeLeft(JSON.parse(savedTimeLeft));
      setProgress(
        (JSON.parse(savedQuestionIndex) / JSON.parse(savedQuestions).length) *
          100,
      );
      setLoaded(true);
    } else {
      fetchQuestionsFromAPI(setQuestions, setProgress, setLoaded);
    }
  }, []);

  // Save state to sessionStorage whenever it changes
  useEffect(() => {
    if (questions.length > 0) {
      sessionStorage.setItem("questions", JSON.stringify(questions));
      sessionStorage.setItem(
        "currentQuestionIndex",
        JSON.stringify(currentQuestionIndex),
      );
      sessionStorage.setItem("score", JSON.stringify(score));
      sessionStorage.setItem("timeLeft", JSON.stringify(timeLeft));
    }
  }, [questions, currentQuestionIndex, score, timeLeft]);

  const handleNextQuestion = useCallback(() => {
    setShowAnswer(false);
    setSelectedOption(null);
    setCorrectOption(null);
    setTimeLeft(15);
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      setProgress(100);
      setTimeout(() => {
        sessionStorage.setItem("score", JSON.stringify(score));
        navigate("/result", {
          replace: true,
          state: { score, total: questions.length },
        });
      }, 1500); // Ensure the score is updated and progress bar is full before navigating
    }
  }, [currentQuestionIndex, questions.length, score, navigate]);

  useEffect(() => {
    const fetchCorrectAnswer = async () => {
      const question = questions[currentQuestionIndex];
      const questionId = question._id;
      const timeLapsed = 15 - timeLeft;

      try {
        const response = await submitAnswer(questionId, "", timeLapsed);
        setCorrectOption(response.data.correctOptionValue);
        setShowAnswer(true);

        // Store the time lapsed for the current question
        const totalTimeTaken =
          parseInt(sessionStorage.getItem("totalTimeTaken") || "0", 10) +
          timeLapsed;
        sessionStorage.setItem("totalTimeTaken", totalTimeTaken.toString());

        setTimeout(() => {
          handleNextQuestion();
        }, 1500);
      } catch (error) {
        console.error("Failed to fetch correct answer:", error);
      }
    };

    if (timeLeft > 0 && !showAnswer) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else if (timeLeft === 0 && !showAnswer) {
      fetchCorrectAnswer();
    }
  }, [
    timeLeft,
    showAnswer,
    handleNextQuestion,
    currentQuestionIndex,
    questions,
  ]);

  useEffect(() => {
    if (timeLeft > 0 && !showAnswer) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else if (timeLeft === 0 && !showAnswer) {
      setShowAnswer(true);
      setCorrectOption(questions[currentQuestionIndex]?.correctOptionValue);
      setTimeout(() => {
        handleNextQuestion();
      }, 1500);
    }
  }, [
    timeLeft,
    showAnswer,
    handleNextQuestion,
    currentQuestionIndex,
    questions,
  ]);

  useEffect(() => {
    if (questions.length > 0) {
      setProgress((currentQuestionIndex / questions.length) * 100);
    }
  }, [currentQuestionIndex, questions.length]);

  const handleOptionClick = async (option: string) => {
    if (timeLeft === 15) {
      // Timer hasn't started yet, don't allow answer selection
      return;
    }

    const question = questions[currentQuestionIndex];
    const questionId = question._id;
    const timeLapsed = 15 - timeLeft;

    try {
      const response = await submitAnswer(questionId, option, timeLapsed);
      setSelectedOption(option);
      let newScore = score;
      if (response.data.result) {
        newScore += 1;
        setScore(newScore);
        setCorrectOption(option);
      } else {
        setCorrectOption(response.data.correctOptionValue);
      }
      setShowAnswer(true);

      // Store the time lapsed for the current question
      const totalTimeTaken =
        parseInt(sessionStorage.getItem("totalTimeTaken") || "0", 10) +
        timeLapsed;
      sessionStorage.setItem("totalTimeTaken", totalTimeTaken.toString());

      if (currentQuestionIndex < questions.length - 1) {
        setTimeout(() => {
          handleNextQuestion();
        }, 1500);
      } else {
        setProgress(100);
        setTimeout(() => {
          sessionStorage.setItem("score", JSON.stringify(newScore));
          navigate("/result", {
            replace: true,
            state: { score: newScore, total: questions.length, totalTimeTaken },
          });
        }, 1500);
      }
    } catch (error) {
      console.error("Failed to submit answer:", error);
    }
  };

  return (
    <CSSTransition in={loaded} timeout={600} classNames="fade" unmountOnExit>
      <div className="background-image flex min-h-screen flex-col items-center px-6 py-10 font-mika">
        <Logos />
        <div className="flex w-full items-center justify-between">
          <div className="text-right text-base font-semibold text-white">
            Score: {score}
          </div>
          <div className="text-right text-base font-semibold text-white">
            Time left: {timeLeft}s
          </div>
        </div>
        <div className="mt-2 w-full">
          <div className="h-1 w-full rounded bg-[#ffffff44]">
            <div
              className="h-full rounded bg-white"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
          {loaded && questions.length > 0 && (
            <CSSTransition
              key={currentQuestionIndex}
              timeout={600}
              classNames="fade"
            >
              <Question
                question={questions[currentQuestionIndex]?.question}
                options={questions[currentQuestionIndex]?.options}
                currentQuestionIndex={currentQuestionIndex}
                selectedOption={selectedOption}
                correctOption={correctOption}
                showAnswer={showAnswer}
                handleOptionClick={handleOptionClick}
              />
            </CSSTransition>
          )}
        </div>
      </div>
    </CSSTransition>
  );
};

export default GamePage;
