import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const getParticipantProfile = async (token: string) => {
  try {
    const response = await axios.get(
      `${API_URL}/quiz-trivia/participant-profile`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching participant profile:", error);
    throw error;
  }
};
