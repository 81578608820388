import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import LandingPage from "./pages/LandingPage";
import ResultPage from "./pages/ResultPage";
import LeaderboardPage from "./pages/LeaderboardPage";
import GamePage from "./pages/GamePage";
import PageNotFoundPage from "./pages/PageNotFoundPage";

const App: React.FC = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route
            path="/game"
            element={<ProtectedRoute element={<GamePage />} />}
          />
          <Route
            path="/result"
            element={<ProtectedRoute element={<ResultPage />} />}
          />
          <Route path="/leaderboard" element={<LeaderboardPage />} />{" "}
          <Route path="*" element={<PageNotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
