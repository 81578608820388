import React, { createContext, useContext, useState, ReactNode } from "react";

interface ResourceContextProps {
  resourcesLoaded: boolean;
  setResourcesLoaded: (loaded: boolean) => void;
}

interface ResourceProviderProps {
  children: ReactNode;
}

const ResourceContext = createContext<ResourceContextProps | undefined>(
  undefined,
);

export const ResourceProvider: React.FC<ResourceProviderProps> = ({
  children,
}) => {
  const [resourcesLoaded, setResourcesLoaded] = useState(false);

  return (
    <ResourceContext.Provider value={{ resourcesLoaded, setResourcesLoaded }}>
      {children}
    </ResourceContext.Provider>
  );
};

export const useResourceContext = () => {
  const context = useContext(ResourceContext);
  if (!context) {
    throw new Error(
      "useResourceContext must be used within a ResourceProvider",
    );
  }
  return context;
};
