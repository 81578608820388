import React from "react";

interface QuestionProps {
  question: string;
  options: string[];
  currentQuestionIndex: number;
  selectedOption: string | null;
  correctOption?: string | null;
  showAnswer: boolean;
  handleOptionClick: (option: string) => void;
}

const Question: React.FC<QuestionProps> = ({
  question,
  options,
  currentQuestionIndex,
  selectedOption,
  correctOption,
  showAnswer,
  handleOptionClick,
}) => {
  return (
    <div className="mt-4 rounded p-2">
      <h2 className="mb-6 text-lg tracking-wide text-white">
        Q{currentQuestionIndex + 1}. {question}
      </h2>
      <div className="grid grid-cols-1 gap-4">
        {options.map((option, index) => (
          <button
            key={index}
            onClick={() => handleOptionClick(option)}
            className={`h-12 rounded-[16px] border px-3.5 text-[15px] font-medium tracking-wide shadow-custom-lg ${
              showAnswer
                ? option === correctOption
                  ? "border-green-500 bg-green-500 text-white"
                  : selectedOption === option
                    ? "bg-venetian-red border-red-500 text-white"
                    : "text-venetian-red border-transparent bg-white opacity-80"
                : "text-venetian-red border-transparent bg-white"
            }`}
            disabled={showAnswer}
          >
            {option}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Question;
