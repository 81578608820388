import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

interface UserData {
  name: string;
  email: string;
  phoneNumber: string;
}

export const participantData = async (userData: UserData) => {
  try {
    const response = await axios.post(
      `${API_URL}/quiz-trivia/participate`,
      userData,
    );
    const { accessToken } = response.data.data;
    sessionStorage.setItem("KSYomxUeTK", accessToken);
    return accessToken;
  } catch (error) {
    console.error("Error submitting user data:", error);
    throw error;
  }
};
