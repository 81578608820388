import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const fetchLeaderboard = async (date: string) => {
  try {
    const response = await axios.get(`${API_URL}/quiz-trivia/leaderboard`, {
      params: { date },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching leaderboard data:", error);
    throw error;
  }
};
