import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import { useNavigate } from "react-router-dom";
import Logos from "../components/Logos";
import "../styles/FadeTransition.css";

const ResultPage: React.FC = () => {
  const navigate = useNavigate();

  const [loaded, setLoaded] = useState<boolean>(false);
  const [score, setScore] = useState<number>(0);

  useEffect(() => {
    const savedScore = sessionStorage.getItem("score");
    if (savedScore) {
      setScore(JSON.parse(savedScore));
    }

    setTimeout(() => setLoaded(true), 600);
  }, []);

  const getMessage = () => {
    return score === 10
      ? "You win"
      : "Fantastic! Check out the phone to learn more.";
  };

  return (
    <div className="background-image flex h-screen items-center justify-center px-4 font-mika">
      <CSSTransition in={loaded} timeout={600} classNames="fade" unmountOnExit>
        <div className="flex h-full w-full items-center justify-center p-10">
          <div className="flex flex-col items-center">
            <Logos />
            <div className="flex h-56 w-56 items-center justify-center rounded-full bg-venetian-red p-10 text-center font-nothing text-5xl font-medium uppercase leading-tight tracking-wide text-white shadow-inner-lg hover:bg-[#d92e1bea]">
              <span className={`${score === 10 ? "" : "text-lg"}`}>
                {getMessage()}
              </span>
            </div>
            <div className="mt-10 flex w-full max-w-xs flex-col space-y-4">
              <button
                className="mx-auto w-fit rounded-xl bg-venetian-red px-8 py-3 uppercase tracking-widest text-white shadow-inner-lg"
                onClick={() => navigate("/leaderboard")}
              >
                view Leaderboard
              </button>
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default ResultPage;
