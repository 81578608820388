import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const fetchQuestions = async () => {
  try {
    const token = sessionStorage.getItem("KSYomxUeTK");
    const response = await axios.get(`${API_URL}/quiz-trivia/questions`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching questions:", error);
    throw error;
  }
};
