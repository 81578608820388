import React, { useState, useEffect, useRef, useCallback } from "react";
import { toast, Toaster } from "react-hot-toast";
import Logos from "../components/Logos";
import background from "../assets/images/Bg-compressed-compressed.jpg";
import flipkartLogo from "../assets/images/flipkart-compressed.png";
import cmfNothing from "../assets/images/cmf-nothing-compressed.png";
import titleImg from "../assets/images/title.png";
import useImagePreloader from "../hooks/useImagePreloader";
import { participantData } from "../api/participateAPI";
import { getParticipantProfile } from "../api/getParticipantProfileAPI";
import "../styles/FadeTransition.css";
import { CSSTransition } from "react-transition-group";
import { useLocation, useNavigate } from "react-router-dom";
import { useResourceContext } from "../components/ResourceContext";
import { SyncLoader } from "react-spinners";

const LandingPage: React.FC = () => {
  const { resourcesLoaded, setResourcesLoaded } = useResourceContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const toastId = useRef<string | null>(null);

  const imageUrls = [background, flipkartLogo, cmfNothing];
  const imagesLoaded = useImagePreloader(imageUrls);

  const fontsLoaded = () =>
    document.fonts.ready.then(() => {
      return true;
    });

  useEffect(() => {
    if (location.pathname === "/") {
      sessionStorage.clear();
    }
    const loadResources = async () => {
      await Promise.all([fontsLoaded(), imagesLoaded]);
      setResourcesLoaded(true);
      setTimeout(() => setLoaded(true), 600);
    };

    if (resourcesLoaded) {
      setTimeout(() => setLoaded(true), 600);
    } else {
      loadResources();
    }
  }, [imagesLoaded, resourcesLoaded, setResourcesLoaded, location.pathname]);

  const validateName = (name: string): string | undefined => {
    if (!name) {
      return "Name is required";
    }
    if (/\d/.test(name)) {
      return "Name cannot contain numbers";
    }
    return undefined;
  };

  const validateEmail = (email: string): string | undefined => {
    if (!email) {
      return "Email is required";
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return "Invalid email address";
    }
    return undefined;
  };

  const validatePhoneNumber = (phoneNumber: string): string | undefined => {
    if (!phoneNumber) {
      return "Phone number is required";
    }
    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(phoneNumber)) {
      return "Phone number must be 10 digits";
    }
    return undefined;
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setPhoneNumber(value);
    }
  };

  const handlePlayButtonClick = useCallback(async () => {
    if (toastId.current) {
      return;
    }

    const nameError = validateName(name);
    const emailError = validateEmail(email);
    const phoneError = validatePhoneNumber(phoneNumber);

    const displayToast = (message: string) => {
      if (toastId.current) {
        toast.dismiss(toastId.current);
      }
      toastId.current = toast.error(message);
      setTimeout(() => {
        toastId.current = null;
      }, 3000);
    };

    if (!name || !email || !phoneNumber) {
      displayToast("Please fill in all fields");
      return;
    }

    if (nameError || emailError || phoneError) {
      if (nameError) {
        displayToast(nameError);
      } else if (emailError) {
        displayToast(emailError);
      } else if (phoneError) {
        displayToast(phoneError);
      }
      return;
    }

    setIsSubmitting(true);

    try {
      const accessToken = await participantData({ name, email, phoneNumber });

      try {
        await getParticipantProfile(accessToken);
        navigate("/game", { replace: true });
      } catch (profileError: any) {
        const errorMessage =
          profileError.response?.data?.message || "Failed to fetch profile";
        displayToast(errorMessage);
        setIsSubmitting(false);
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message || "Oops! Something went wrong!";
      displayToast(errorMessage);
      setIsSubmitting(false);
      setName("");
      setEmail("");
      setPhoneNumber("");
    }
  }, [name, email, phoneNumber, navigate]);

  return (
    <div>
      <Toaster />
      <CSSTransition in={loaded} timeout={600} classNames="fade" unmountOnExit>
        <div className="background-image flex min-h-screen flex-col items-center justify-center px-4 font-nothing">
          <div className="mx-auto flex w-[95%] max-w-md flex-col items-center rounded-lg bg-opacity-75 px-4 py-2">
            <Logos />
            <img
              src={titleImg}
              alt="phone-power-hour"
              className="mb-8 h-44 w-auto"
            />
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mb-2 h-12 w-full rounded-[10px] px-4 font-mika placeholder-red-500 shadow-inner outline-none focus:outline-none"
              style={{ boxShadow: "inset 0 2px 4px rgba(0, 0, 0, 0.2)" }}
            />
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="h-12 w-full rounded-[10px] px-4 font-mika placeholder-red-500 shadow-inner outline-none focus:outline-none"
              style={{ boxShadow: "inset 0 2px 4px rgba(0, 0, 0, 0.2)" }}
            />
            <input
              type="tel"
              placeholder="Phone Number"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              className="mb-6 mt-2 h-12 w-full rounded-[10px] px-4 font-mika placeholder-red-500 shadow-inner outline-none focus:outline-none"
              style={{ boxShadow: "inset 0 2px 4px rgba(0, 0, 0, 0.2)" }}
            />
            <button
              onClick={handlePlayButtonClick}
              disabled={isSubmitting}
              className="h-12 w-full rounded-[10px] bg-venetian-red font-mika text-lg font-medium uppercase tracking-widest text-white shadow-inner-lg hover:bg-[#d92e1bea]"
            >
              {isSubmitting ? (
                <div className="flex justify-center">
                  <SyncLoader color="white" size={6} />
                </div>
              ) : (
                "Play"
              )}
            </button>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default LandingPage;
